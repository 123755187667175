/* eslint-disable max-len */

import { getYears } from '../functions/StaticFunctions';

import {
  nameRules,
  birthDayRules,
  phoneRules,
  emailRules,
  plzRules,
  genderRule,
  attendanceRule,
  sorgTypeRule
} from '../validationRules';

export const CONST_STATUS = { DISPLAY: 0, NEW: 1, EDIT: 2 };

export const CONST_CAMP_GROUPS = [
  'Gruppe 1',
  'Gruppe 2',
  'Gruppe 3',
  'Gruppe 4'
];

export const CONST_CAMP_BEDS = [
  'Tür unten',
  'Tür oben',
  'Fenster unten',
  'Fenster oben'
];

export const CONST_CAMP_DATA = new Map([
  ['Gruppe 1', ['014', '015']],
  ['Gruppe 2', ['016', '017']],
  ['Gruppe 3', ['113', '114', '115', '116', '117']],
  ['Gruppe 4', ['213', '214', '215', '216', '217']]
]);

export const CONST_CAMP_ROOMS = [
  '014',
  '015',
  '016',
  '017',
  '113',
  '114',
  '115',
  '116',
  '117',
  '213',
  '214',
  '215',
  '216',
  '217'
];

export const CONST_RULES_MAP = new Map([
  ['nameRule', nameRules],
  ['birthdayRule', birthDayRules],
  ['phoneRule', phoneRules],
  ['emailRule', emailRules],
  ['plzRule', plzRules],
  ['genderRule', genderRule],
  ['attendanceRule', attendanceRule],
  ['sorgTypeRule', sorgTypeRule]
]);

export const CONST_TEAM_FIELDS_NEW = [
  'lastName',
  'firstName',
  'email',
  'emailVerified',
  'phone',
  'campFunction',
  'password'
];

export const CONST_FORM_DATA = [
  {
    fieldset: 'Teilnehmer',
    subNode: 'childData',
    childData: [
      {
        value: 'familyName',
        label: 'Nachname',
        maxlength: 30,
        rules: 'nameRule',
        type: 'text'
      },
      {
        value: 'firstName',
        label: 'Vorname',
        maxlength: 30,
        rules: 'nameRule',
        type: 'text'
      },
      {
        value: 'birthDay',
        label: 'Geburtsdatum (TT.MM.JJJJ)',
        maxlength: 10,
        rules: 'birthdayRule',
        type: 'text'
      },
      {
        value: 'gender',
        label: 'Geschlecht',
        rules: 'genderRule',
        type: 'select',
        selItems: [
          { text: 'männlich', val: 'male' },
          { text: 'weiblich', val: 'female' }
        ]
      },
      {
        value: 'attendance',
        label: 'Bisherige Teilnahmen',
        rules: 'attendanceRule',
        type: 'select',
        selItems: getYears(0)
      }
    ]
  },
  {
    fieldset: 'Sorgeberechtigter',
    subNode: 'parentData',
    parentData: [
      {
        value: 'sorgType',
        label: 'Sorgeberechtigter ist',
        rules: 'sorgTypeRule',
        type: 'select',
        selItems: [
          { text: 'Mutter', val: 'Mutter' },
          { text: 'Vater', val: 'Vater' },
          { text: 'Sonstige', val: 'Sonstige' }
        ]
      },
      {
        value: 'familyName',
        label: 'Nachname',
        maxlength: 30,
        rules: 'nameRule',
        type: 'text'
      },
      {
        value: 'firstName',
        label: 'Vorname',
        maxlength: 30,
        rules: 'nameRule',
        type: 'text'
      },
      {
        value: 'phone',
        label: 'Telefon',
        maxlength: 14,
        rules: 'phoneRule',
        type: 'text'
      },
      {
        value: 'email',
        label: 'eMail',
        maxlength: 30,
        rules: 'emailRule',
        type: 'text'
      },
      {
        value: 'zip',
        label: 'PLZ',
        maxlength: 5,
        rules: 'plzRule',
        type: 'text'
      },
      {
        value: 'city',
        label: 'Wohnort',
        maxlength: 30,
        rules: 'nameRule',
        type: 'text'
      },
      {
        value: 'street',
        label: 'Strasse',
        maxlength: 50,
        rules: 'nameRule',
        type: 'text'
      }
    ]
  },
  {
    fieldset: 'Medizinische Daten',
    subNode: 'medicData',
    medicData: {
      subNodes: [
        {
          dataName: 'doctor',
          header: 'Diabetes',
          doctor: [
            {
              value: 'name',
              label: 'Behandelnder Diabetologe/-in',
              maxlength: 30,
              type: 'text',
              notRequired: true
            },
            {
              value: 'surgery',
              label: 'Praxis / Klinik',
              maxlength: 30,
              type: 'text',
              notRequired: true
            },
            {
              value: 'diabetesSince',
              label: 'Diabetesdauer',
              type: 'select',
              selItems: getYears(2),
              notRequired: true
            },
            {
              value: 'therapie',
              label: 'Therapieform',
              type: 'select',
              selItems: [
                { text: 'Spritze', val: 'Spritze' },
                { text: 'Pumpe', val: 'Pumpe' }
              ],
              notRequired: true
            }
          ]
        },
        {
          dataName: 'allergies',
          header: 'Allergien & Unverträglichkeiten',
          allergies: [
            {
              value: 'celiac',
              label: 'Zöliakie',
              type: 'checkbox',
              notRequired: true
            },
            {
              value: 'lactose',
              label: 'Laktose-Intoleranz',
              type: 'checkbox',
              notRequired: true
            },
            {
              value: 'nut',
              label: 'Nussallergie. Welche Nüsse?',
              maxlength: 50,
              type: 'checkboxWithInput',
              notRequired: true
            },
            {
              value: 'fruit',
              label: 'Obst-Unverträglichkeit. Welche Sorten?',
              maxlength: 50,
              type: 'checkboxWithInput',
              notRequired: true
            }
          ]
        },
        {
          dataName: 'dieseases',
          header: 'Andere Erkrankungen',
          dieseases: [
            {
              value: 'epilepsy',
              label: 'Epilepsie',
              type: 'checkbox',
              notRequired: true
            },
            {
              value: 'thyroid',
              label: 'Schilddrüsenerkrankung',
              type: 'checkbox',
              notRequired: true
            },
            {
              value: 'misc',
              label: 'Sonstiges?',
              maxlength: 50,
              type: 'checkboxWithInput',
              notRequired: true
            }
          ]
        }
      ]
    }
  },
  {
    fieldset: 'Zusätzliche Informationen',
    subNode: 'additionalData',
    additionalData: [
      {
        value: 'additionalData',
        label: 'Freitext',
        maxlength: 1000,
        type: 'text',
        notRequired: true
      }
    ]
  },
  {
    fieldset: 'Camp-Organization',
    subNode: 'campData',
    campData: [
      {
        value: 'group',
        label: 'Gruppe',
        type: 'select',
        selItems: CONST_CAMP_GROUPS,
        notRequired: true,
        disabled: true
      },
      {
        value: 'room',
        label: 'Zimmer',
        type: 'select',
        selItems: CONST_CAMP_ROOMS,
        notRequired: true,
        disabled: true
      },
      {
        value: 'bed',
        label: 'Bett',
        type: 'select',
        selItems: CONST_CAMP_BEDS,
        notRequired: true,
        disabled: true
      }
    ]
  }
];
