/* eslint-disable max-len */
import moment from 'moment';

const nameRules = [
  v => !!v || 'Eingabe erforderlich',
  v => (v && v.length >= 2) || 'Bitte mindestens 2 Zeichen eingeben'
];

const birthDayRules = [
  v => !!v || 'Eingabe erforderlich',
  v =>
    (v && v.match(/^\d{2}[.]\d{2}[.]\d{4}$/) !== null) ||
    'Bitte im Format TT.MM.JJJJ eingeben',
  v =>
    (v && moment(v, 'DD.MM.YYYY').isValid()) || 'Bitte gültiges Datum eingeben',
  v =>
    moment('04.07.2021', 'DD.MM.YYYY').diff(moment(v, 'DD.MM.YYYY'), 'years') >
      7 || 'Sorry, Mindestalter 6 Jahre',
  v =>
    moment('04.07.2021', 'DD.MM.YYYY').diff(moment(v, 'DD.MM.YYYY'), 'years') <
      16 || 'Sorry, Höchstalter 16 Jahre'
];

const plzRules = [
  v => !!v || 'Eingabe erforderlich',
  v =>
    (v && v.match(/^([0]{1}[1-9]{1}|[1-9]{1}[0-9]{1})[0-9]{3}$/) !== null) ||
    '5 Ziffern'
];

const phoneRules = [
  v => !!v || 'Eingabe erforderlich',
  v => (v && v.length >= 5) || 'Bitte mindestens 5 Ziffern eingeben',
  v =>
    (v && v.match(/^[+\d]+(?:[\d-.\s()]*)$/) !== null) ||
    'Bitte gültige Telefonnummer eingeben'
];

const emailRules = [
  v => !!v || 'Eingabe erforderlich',
  // eslint-disable-next-line no-useless-escape
  v =>
    (v &&
      v.match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      ) !== null) ||
    'Bitte gültige eMail-Adresse eingeben'
];

const genderRule = [v => !!v || 'Bitte das Geschlecht auswählen'];

const attendanceRule = [v => !!v || v >= 0 || 'Bitte Teilnahmen auswählen'];

const sorgTypeRule = [v => !!v || 'Auswahl erforderlich'];

export {
  nameRules,
  birthDayRules,
  plzRules,
  phoneRules,
  emailRules,
  genderRule,
  attendanceRule,
  sorgTypeRule
};
