<template>
  <v-container>
    <v-row class="text-center my-4">
      <v-toolbar color="#F9F7F7" dense>
        <v-spacer></v-spacer>
        <v-btn
          icon
          color="blue"
          @click="$store.dispatch('ACT_GET_TEAMMEMBERS')"
        >
          <v-icon>mdi-refresh</v-icon>
        </v-btn>
        <v-btn
          v-if="$store.state.loginModule.isAdmin"
          icon
          color="green"
          @click="$refs.add.open()"
        >
          <v-icon>mdi-plus</v-icon>
        </v-btn>
      </v-toolbar>
    </v-row>
    <v-row class="text-center">
      <v-data-table
        :headers="teamHeader"
        :items="teamData"
        item-key="name"
        class="elevation-1"
        style="width: 100%"
      >
        <template v-slot:[`item.editAction`]="{ item }">
          <!-- Edit Dialog -->
          <v-icon
            v-if="$store.state.loginModule.isAdmin"
            small
            color="green"
            @click.stop="$refs.add.open(item)"
          >
            mdi-pencil
          </v-icon>
        </template>
        <template v-slot:[`item.deleteAction`]="{ item }">
          <!-- Delete Dialog Admins should not be deleted to avoid lockout -->
          <v-icon
            v-if="!item.isAdmin && $store.state.loginModule.isAdmin"
            small
            color="red"
            @click.stop="deleteMember(item)"
          >
            mdi-delete
          </v-icon>
        </template>
        <!-- emailVerified Template -->
        <template v-slot:[`item.emailVerified`]="{ item }">
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                small
                :color="item.emailVerified | emailVerifiedColorFlt"
                v-bind="attrs"
                v-on="on"
              >
                {{ item.emailVerified | emailVerifiedIconFlt }}
              </v-icon>
            </template>
            <span> {{ item.emailVerified | emailVerifiedToolTipFlt }}</span>
          </v-tooltip>
        </template>
        <!-- emailVerified Template -->
        <template v-slot:[`item.isAdmin`]="{ item }">
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                small
                :color="item.isAdmin | isAdminColorFlt"
                v-bind="attrs"
                v-on="on"
              >
                {{ item.isAdmin | isAdminIconFlt }}
              </v-icon>
            </template>
            <span> {{ item.isAdmin | isAdminToolTipFlt }}</span>
          </v-tooltip>
        </template>
      </v-data-table>
    </v-row>
    <v-snackbar v-model="snackbar.show" absolute bottom color="green">
      {{ snackbar.text }}
      <template v-slot:action="{ attrs }">
        <v-btn
          :color="snackbar.color"
          fab
          dark
          x-small
          v-bind="attrs"
          @click="snackbar.show = false"
        >
          <v-icon dark> mdi-close </v-icon>
        </v-btn>
      </template>
    </v-snackbar>
    <add-team-member ref="add" />
  </v-container>
</template>

<script>
import teamModule from '../../store/modules/team';

import AddTeamMember from './dialogs/AddTeamMember.vue';

export default {
  components: {
    AddTeamMember
  },

  filters: {
    emailVerifiedIconFlt(value) {
      if (value && value === true) {
        return 'mdi-account-check';
      }
      return 'mdi-account-question';
    },
    emailVerifiedColorFlt(value) {
      if (value && value === true) {
        return 'green';
      }
      return 'blue';
    },
    emailVerifiedToolTipFlt(value) {
      if (value && value === true) {
        return 'eMail bestätigt';
      }
      return 'eMail unbestätigt';
    },
    isAdminIconFlt(value) {
      if (value && value === true) {
        return 'mdi-account-cowboy-hat';
      }
      return 'mdi-account';
    },
    isAdminColorFlt(value) {
      if (value && value === true) {
        return 'red';
      }
      return 'green';
    },
    isAdminToolTipFlt(value) {
      if (value && value === true) {
        return 'Admin';
      }
      return 'Member';
    }
  },

  data: () => ({
    snackbar: {
      text: '',
      show: false,
      color: 'green'
    },
    teamHeader: [
      {
        text: '',
        value: 'editAction',
        sortable: false,
        align: 'center'
      },
      {
        text: 'Vorname',
        align: 'start',
        sortable: true,
        value: 'firstName'
      },
      {
        text: 'Nachname',
        align: 'start',
        sortable: true,
        value: 'lastName'
      },

      {
        text: 'Telefon',
        align: 'start',
        sortable: true,
        value: 'phone'
      },
      {
        text: 'eMail',
        align: 'start',
        sortable: true,
        value: 'email'
      },
      {
        text: 'Status',
        align: 'start',
        sortable: true,
        value: 'emailVerified'
      },
      {
        text: 'Camp-Funktion',
        align: 'start',
        sortable: true,
        value: 'campFunction'
      },
      {
        text: 'App-Berechtigung',
        align: 'start',
        sortable: true,
        value: 'isAdmin'
      },
      {
        text: '',
        value: 'deleteAction',
        sortable: false,
        align: 'center'
      }
    ]
  }),

  computed: {
    teamData() {
      return this.$store.state.teamModule.members;
    }
  },

  created() {
    /* ensure that the store module for login is registered */
    if (!this.$store.hasModule('teamModule')) {
      this.$store.registerModule('teamModule', teamModule);
    }
    if (this.$store.state.teamModule.members.length === 0) {
      this.$store.dispatch('ACT_GET_TEAMMEMBERS').then(() => {});
    }
  },

  methods: {
    deleteMember(item) {
      this.$root.$confirm
        .open({ titel: 'Löschen', msg: `${item.email} wirklich löschen` })
        .then(response => {
          if (response) {
            this.$store
              .dispatch('ACT_DELETE_TEAMMEMBER', item)
              .then(() => {
                this.snackbar.show = true;
                this.snackbar.color = 'green';
                this.snackbar.text = `${item.email} gelöscht.`;
              })
              .catch(error => {
                this.$root.$info.open(error);
              });
          } else {
            this.snackbar.show = true;
            this.snackbar.color = 'green';
            this.snackbar.text = 'Löschen abgebrochen';
          }
        })
        .catch(error => {
          this.snackbar.show = true;
          this.snackbar.color = 'red';
          this.snackbar.text = error;
        });
    }
  }
};
</script>
