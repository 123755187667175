import Vue from 'vue';
import Vuex from 'vuex';
import { ACT_GET_TEAMMEMBERS, MUT_GET_TEAMMEMBERS } from '../storeTypes';

import { CONST_TEAM_FIELDS_NEW } from '../../plugins/constants/Constants';

const Parse = require('parse');

Parse.serverURL = process.env.VUE_APP_PARSE_SERVER_URL;
Parse.initialize(process.env.VUE_APP_PARSE_APP_ID);

Vue.use(Vuex);

const mutations = {
  [MUT_GET_TEAMMEMBERS](s, members) {
    const membList = [];
    members.forEach(e => {
      const obj = {};
      CONST_TEAM_FIELDS_NEW.forEach(field => {
        obj[field] = e.user.get(field);
      });
      obj.id = e.user.id;
      obj.isAdmin = e.isAdmin;
      membList.push(obj);
    });
    const state = s;
    state.members = membList;
  },

  MUT_CREATE_TEAMMEMBERS(state, member) {
    const obj = {};
    CONST_TEAM_FIELDS_NEW.forEach(field => {
      obj[field] = member.get(field);
    });
    obj.id = member.id;
    state.members.push(obj);
  },

  MUT_DELETE_TEAMMEMBER(state, object) {
    const index = state.members.findIndex(element => element.id === object.id);
    state.members.splice(index, 1);
  },

  MUT_EDIT_TEAMMEMBER(state, member) {
    const obj = {};
    CONST_TEAM_FIELDS_NEW.forEach(field => {
      obj[field] = member.get(field);
    });
    obj.id = member.id;
    const index = state.members.findIndex(element => element.id === obj.id);
    state.members.splice(index, 1, obj);
  }
};

const actions = {
  async [ACT_GET_TEAMMEMBERS]({ commit }) {
    // const users = await new Parse.Query(Parse.User).find();
    // commit('MUT_GET_TEAMMEMBERS', users);
    Parse.Cloud.run('getTeamMembers').then(users => {
      commit('MUT_GET_TEAMMEMBERS', users);
    });
  },

  async ACT_CREATE_TEAMMEMBER({ commit }, member) {
    const user = new Parse.User();
    CONST_TEAM_FIELDS_NEW.forEach(field => user.set(field, member[field]));
    user.set('username', member.email);
    user.set('createdBy', Parse.User.current().id);
    await user.save();
    commit('MUT_CREATE_TEAMMEMBERS', user);
  },

  async ACT_DELETE_TEAMMEMBER({ commit }, member) {
    Parse.Cloud.run('deleteTeamMember', member)
      .then(response => {
        if (response.id === member.id) {
          commit('MUT_DELETE_TEAMMEMBER', member);
          return Promise.resolve('deleted');
        }
        return Promise.reject(new Error('error'));
      })
      .catch(err => Promise.reject(new Error(err)));
  },

  async ACT_EDIT_TEAMMEMBER({ commit }, member) {
    const user = member;
    user.modifiedBy = Parse.User.current().id;
    Parse.Cloud.run('editTeamMember', user)
      .then(response => {
        if (response.id === member.id) {
          commit('MUT_EDIT_TEAMMEMBER', response);
          return Promise.resolve('edit');
        }
        return Promise.reject(new Error('error'));
      })
      .catch(err => Promise.reject(new Error(err)));
  }
};

const state = {
  members: []
};

export default {
  state,
  mutations,
  actions
};
