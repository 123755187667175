var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[_c('v-row',{staticClass:"text-center my-4"},[_c('v-toolbar',{attrs:{"color":"#F9F7F7","dense":""}},[_c('v-spacer'),_c('v-btn',{attrs:{"icon":"","color":"blue"},on:{"click":function($event){return _vm.$store.dispatch('ACT_GET_TEAMMEMBERS')}}},[_c('v-icon',[_vm._v("mdi-refresh")])],1),(_vm.$store.state.loginModule.isAdmin)?_c('v-btn',{attrs:{"icon":"","color":"green"},on:{"click":function($event){return _vm.$refs.add.open()}}},[_c('v-icon',[_vm._v("mdi-plus")])],1):_vm._e()],1)],1),_c('v-row',{staticClass:"text-center"},[_c('v-data-table',{staticClass:"elevation-1",staticStyle:{"width":"100%"},attrs:{"headers":_vm.teamHeader,"items":_vm.teamData,"item-key":"name"},scopedSlots:_vm._u([{key:"item.editAction",fn:function(ref){
var item = ref.item;
return [(_vm.$store.state.loginModule.isAdmin)?_c('v-icon',{attrs:{"small":"","color":"green"},on:{"click":function($event){$event.stopPropagation();return _vm.$refs.add.open(item)}}},[_vm._v(" mdi-pencil ")]):_vm._e()]}},{key:"item.deleteAction",fn:function(ref){
var item = ref.item;
return [(!item.isAdmin && _vm.$store.state.loginModule.isAdmin)?_c('v-icon',{attrs:{"small":"","color":"red"},on:{"click":function($event){$event.stopPropagation();return _vm.deleteMember(item)}}},[_vm._v(" mdi-delete ")]):_vm._e()]}},{key:"item.emailVerified",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"small":"","color":_vm._f("emailVerifiedColorFlt")(item.emailVerified)}},'v-icon',attrs,false),on),[_vm._v(" "+_vm._s(_vm._f("emailVerifiedIconFlt")(item.emailVerified))+" ")])]}}],null,true)},[_c('span',[_vm._v(" "+_vm._s(_vm._f("emailVerifiedToolTipFlt")(item.emailVerified)))])])]}},{key:"item.isAdmin",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"small":"","color":_vm._f("isAdminColorFlt")(item.isAdmin)}},'v-icon',attrs,false),on),[_vm._v(" "+_vm._s(_vm._f("isAdminIconFlt")(item.isAdmin))+" ")])]}}],null,true)},[_c('span',[_vm._v(" "+_vm._s(_vm._f("isAdminToolTipFlt")(item.isAdmin)))])])]}}],null,true)})],1),_c('v-snackbar',{attrs:{"absolute":"","bottom":"","color":"green"},scopedSlots:_vm._u([{key:"action",fn:function(ref){
var attrs = ref.attrs;
return [_c('v-btn',_vm._b({attrs:{"color":_vm.snackbar.color,"fab":"","dark":"","x-small":""},on:{"click":function($event){_vm.snackbar.show = false}}},'v-btn',attrs,false),[_c('v-icon',{attrs:{"dark":""}},[_vm._v(" mdi-close ")])],1)]}}]),model:{value:(_vm.snackbar.show),callback:function ($$v) {_vm.$set(_vm.snackbar, "show", $$v)},expression:"snackbar.show"}},[_vm._v(" "+_vm._s(_vm.snackbar.text)+" ")]),_c('add-team-member',{ref:"add"})],1)}
var staticRenderFns = []

export { render, staticRenderFns }