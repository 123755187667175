const CONST_ITEM_COUNT_LABELS = new Map([
  [0, ' Mal'],
  [1, ' Jahren'],
  [2, ' Jahre']
]);

const getYears = i => {
  const txt = CONST_ITEM_COUNT_LABELS.get(i);

  const max = i === 0 ? 7 : 14;
  const years = [];
  for (let index = 0; index < max; index += 1) {
    years.push({ val: index, text: index + txt });
  }
  return years;
};

// eslint-disable-next-line import/prefer-default-export
export { getYears };
