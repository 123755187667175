<template>
  <v-row justify="center">
    <v-dialog
      v-model="dialog"
      fullscreen
      hide-overlay
      transition="dialog-bottom-transition"
    >
      <v-card>
        <v-toolbar dark color="primary">
          <v-btn icon dark @click="dialog = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-toolbar-title>
            {{
              isEdit
                ? member.email + ' bearbeiten'
                : 'Neues Team-Mitglied hinzufügen'
            }}
          </v-toolbar-title>
          <v-spacer></v-spacer>
          <v-toolbar-items> </v-toolbar-items>
        </v-toolbar>

        <v-container class="grey lighten-5">
          <v-row>
            <v-col align-self="center">
              <v-text-field
                v-model="member.firstName"
                label="Vorname"
                outlined
              ></v-text-field>
            </v-col>
            <v-col align-self="center">
              <v-text-field
                v-model="member.lastName"
                label="Nachname"
                outlined
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col align-self="center">
              <v-text-field
                v-model="member.phone"
                label="Telefon"
                outlined
              ></v-text-field>
            </v-col>
            <v-col align-self="center">
              <v-text-field
                v-model="member.email"
                :disabled="isEdit"
                :label="isEdit ? 'eMail (= Username)' : 'eMail'"
                outlined
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col align-self="center">
              <v-text-field
                v-model="member.campFunction"
                label="Camp-Funktion"
                outlined
              ></v-text-field>
            </v-col>
            <v-col align-self="center">
              <v-text-field
                v-if="!isEdit"
                v-model="member.password"
                label="initiales Passwort"
                hint="Benutzer bekommt eine Passwort-Reset-eMail"
                outlined
              ></v-text-field>
            </v-col>
          </v-row>
        </v-container>
        <v-card-actions>
          <v-spacer />
          <v-btn text color="grey" @click="dialog = false"> Abbrechen </v-btn>
          <v-btn dark color="green" @click="createMember"> Speichern </v-btn>
          <v-spacer />
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
const Parse = require('parse');

export default {
  data() {
    return {
      dialog: false,
      isEdit: false,
      member: {
        firstName: '',
        lastName: '',
        email: '',
        phone: '',
        campFunction: '',
        password: ''
      }
    };
  },

  methods: {
    open(item) {
      this.dialog = true;
      this.isEdit = !!item;
      if (item) {
        this.member = item;
      }
    },

    createMember() {
      if (this.isEdit) {
        this.$store
          .dispatch('ACT_EDIT_TEAMMEMBER', this.member)
          .then(() => {
            const dialog = {};
            dialog.title = '';
            dialog.msg = `${this.member.email} geändert`;
            this.$root.$info.open(dialog);
            this.dialog = false;
          })
          .catch(error => {
            this.$root.$info.open({ msg: error, title: 'Fehler' });
          });
      } else {
        this.$store
          .dispatch('ACT_CREATE_TEAMMEMBER', this.member)
          .then(() => {
            Parse.User.requestPasswordReset(this.member.email)
              .then(() => {
                const dialog = {};
                dialog.title = 'Neues Teammitglied angelegt';
                dialog.msg = `${this.member.firstName}wurde angelegt.
            Es wird eine email an ${this.member.email} für die Überprüfung und 
            das Setzen eines eigenen Passworts geschickt.`;
                this.$root.$info.open(dialog);
                this.dialog = false;
              })
              .catch(error => {
                this.$root.$info.open(error);
              });
          })
          .catch(error => {
            this.$root.$info.open({ msg: error, title: 'Fehler' });
          });
      }
    }
  }
};
</script>
